import React from 'react'
import { PageProps } from 'gatsby'

import { PageLayout } from '../components/layout/PageLayout'
import { SEO } from '../components/SEO'

const NotFoundPage: React.FC<PageProps> = () => (
  <PageLayout>
    <SEO title="Pagina niet gevonden" />
    <h1>Oeps!</h1>
    <p>
      Deze pagina bestaat niet (meer).
      <br />
      Klik door naar onze <a href="/">homepage</a>.
    </p>
  </PageLayout>
)

export default NotFoundPage
