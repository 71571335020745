import React from 'react'
import styled from 'styled-components'

import palette from '../../styles/palette'

interface Props {
  height?: number
}

export const ColorBar: React.FC<Props> = ({ height = 20 }) => (
  <Wrapper height={height}>
    <Block color={palette.green} />
    <Block color={palette.lightGreen} />
    <Block color={palette.pink} />
    <Block color={palette.orange} />
    <Block color={palette.gold} />
  </Wrapper>
)

const Wrapper = styled.div<{ height: number }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: ${({ height }) => height}px;
`

const Block = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  //width: 100%;
  //padding-top: 100%; // 1:1 aspect ratio
`
