import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'

import themes from '../../styles/themes'
import GlobalStyle from './GlobalStyle'
import { ColorBar } from './ColorBar'
import { Logo as Logo_ } from './Logo'
import { DEFAULT_BREAK_POINT } from '../../styles/base'

interface Props {
  image?: string
}

export const PageLayout: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={themes.base}>
    <GlobalStyle />
    <Header>
      <ColorBar />
      <Link to="/">
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Link>
    </Header>
    <Body>{children}</Body>
  </ThemeProvider>
)

const Header = styled.div`
  margin-bottom: 30px;
`

const Body = styled.div`
  max-width: 800px;
  margin: 20px auto 0;
  padding: 20px;
`

const LogoWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`

const Logo = styled(Logo_)`
  color: ${({ theme }) => theme.colors.logo};
  height: 100px;
  @media ${DEFAULT_BREAK_POINT} {
    height: 100px;
  }
  width: auto;
`
